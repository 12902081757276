<template>
  <div>
    <a-form :form="form" :layout="formLayout" @submit="handleSubmit">
      <!--
      <div v-for="(k,index) in dynamicForm" :key="index">
        <a-form-item
          v-if="k.type == 'text'"
          :label="k.label"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input :placeholder="k.placeholder" v-decorator="[k.name, { rules: [ k.rules ] }]" />
        </a-form-item>
        <a-form-item
          v-if="k.type == 'number'"
          :label="k.label"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input-number
            :min="k.minValue"
            :max="k.maxValue"
            v-decorator="[k.name, { rules: [ k.rules ] }]"
          />
        </a-form-item>
      </div>
      -->
          <a-form-item label="小说名称" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
          <a-input placeholder="小说名称" allow-clear
            v-decorator="['name', { rules: [{ required: true, message: '请输入小说名称!' }] }]"
          ><a-icon slot="prefix" type="book" />
          </a-input>
          </a-form-item>
          <a-form-item label="章节地址" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-input placeholder="http://" allow-clear size="large"
            v-decorator="['url', { rules: [{ required: true, message: '请输入小说章节地址!' }] }]"
            >
            <a-icon slot="prefix" type="global" />
            </a-input>
          </a-form-item>
          <a-form-item label="章节排序" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-select v-decorator="['sort']">
              <a-select-option value="1">正序</a-select-option>
              <a-select-option value="2">倒序</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="页面编码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-select v-decorator="['pageEncoding']">
              <a-select-option value="utf-8">UTF-8</a-select-option>
              <a-select-option value="gb2312">GB2312</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="过滤字符" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
          <a-textarea placeholder="使用|来分割要过滤的字符" :auto-size="{ minRows: 2, maxRows: 6 }"
          v-decorator="['filter']">
          </a-textarea>
          </a-form-item>
          <a-form-item label="阈值(涉及正文试别精准度，根据不同网站适当精调)" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <template>
              <a-slider v-decorator="['threshold']" :max="400" :min="10" :tooltip-visible="true">
              </a-slider>
            </template>
          </a-form-item>
        
      <a-button :disabled="storeNovel.id>0" :style="{ marginRight: '8px'}" @click="handleOnReset">复位</a-button>
      <a-button type="primary" html-type="submit">保存</a-button>
    </a-form>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    layout: {
      type: String,
      default: "vertical"
    },
    fields: {
      name: {
        value: ""
      },
      url: {
        value: ""
      },
      pageEncoding :{
        value: "UTF-8"
      },
      filter: {
        value: ""
      },
      sort: {
        value: "1"
      },
      threshold:{
        value: 150
      }
    }
  },
  //props: ['formLayout','fields'],
  data() {
    return {
      
      formLayout: this.layout,
      form: this.$form.createForm(this, { name: "coordinated" }),
      dynamicForm: [
        {
          name: "name",
          label: "小说名称",
          type: "text",
          placeholder: "小说名称",
          rules: {
            required: true,
            message: "请输入小说名称!"
          }
        },
        {
          name: "url",
          label: "章节地址",
          type: "text",
          placeholder: "http://",
          rules: {
            required: true,
            message: "请输入小说章节地址!"
          }
        },
        {
          name: "pageEncodeing",
          label: "页面编码",
          type: "text",
          placeholder: "utf-8",
          rules: {
            required: true,
            message: "请输入页面编码！"
          }
        },
        {
          name: "threshold",
          label: "噪声阈值",
          type: "text",
          placeholder: "utf-8",
          rules: {
            required: true,
            message: "请输入页面编码！"
          }
        },
        {
          name: "displayCount",
          label: "显示章节(0代表不限制)",
          type: "number",
          placeholder: "",
          minValue: 0,
          maxValue: 9999,
          rules: {
            required: true,
            message: "请输入要显示的章节数."
          }
        }
      ]
    };
  },
  computed: {
    storeNovel() {
      //获取store中的数据，需要将代码放置在computed中方可。
      return this.$store.state.novel;
    },
    formItemLayout() {
      const { formLayout } = this;
      return formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : {};
    }
  },
  created() {
    this.form = this.$form.createForm(this, {
      onFieldsChange: (_, changedFields) => {
        this.$emit("change", changedFields);
      },
      mapPropsToFields: () => {
        return {
          name: this.$form.createFormField({
            ...this.fields.name,
            initialValue: this.fields.name.value
          }),
          url: this.$form.createFormField({
            ...this.fields.url,
            value: this.fields.url.value
          }),
          sort: this.$form.createFormField({
            ...this.fields.sort,
            value: this.fields.sort.value,
            initialValue: this.fields.sort.value,
          }),
          pageEncoding: this.$form.createFormField({
            ...this.fields.pageEncoding,
            initialValue: this.fields.pageEncoding.value,
          }),
          filter: this.$form.createFormField({
            ...this.fields.filter,
            value: this.fields.filter.value
          }),
          threshold: this.$form.createFormField({
            ...this.fields.threshold,
            value: this.fields.threshold.value,
          }),
        };
      },
      onValuesChange: (_, values) => {
        //console.log(values);
        // Synchronize to vuex store in real time
        //this.$store.commit('update', values)
      }
    });
  },
  methods: {
    moment,
    handleOnReset() {
      var novel = this.storeNovel;
      this.$store.state.novel = {
        id: 0,
        name: "",
        url: "",
        sort: "1",
        pageEncoding: "UTF-8",
        filter:'',
        threshold:150
      };
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //添加
          const addNovel = data => {
            const base_API_URL = "/api/novel/addNovel";

            this.$axios
              .post(base_API_URL, data, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.$store.state.appCode
                }
              })
              .then(res => {
                this.$message.success("小说添加成功！");
                this.$store.commit("setNovel", res.data.data);

                //触发复位表单事件
                //this.$emit('Reset', this.defaultFields);
                //触发保存后事件
                //this.$emit('Saved', res.data, 'add');
              })
              .catch(error => {
                //console.warn(error);
                console.log("error");
                this.$message.error("发生错误！");
              });
          };

          const editNovel = data => {
            const base_API_URL = "/api/novel/updateNovel";

            this.$axios
              .post(base_API_URL, data, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.$store.state.appCode
                }
              })
              .then(res => {
                this.$message.success("小说修改成功！");
                this.$store.commit("setNovel", JSON.parse(data));
                //console.log("setNovel")
                //console.log(typeof(data))
                //console.log('setNovel end')
              })
              .catch(error => {
                //console.warn(error);
                console.log("error");
                this.$message.error("发生错误！");
              });
          };
          var data = JSON.stringify({
            id: this.storeNovel.id,
            name: values.name,
            url: values.url,
            sort: parseInt(values.sort),
            pageEncoding: values.pageEncoding,
            filter: values.filter,
            threshold: values.threshold
          });

          if (this.storeNovel.id == 0) {
            addNovel(data);
          } else {
            //清除cache
            sessionStorage.removeItem(this.storeNovel.id);
            //console.log("storeNovel")
            //console.log(this.storeNovel)
            //console.log(data);
            editNovel(data);
          }

          this.$store.commit("setEditFormVisible", false);
          this.$router.push(
            {
              path: "/home",
              redirect: "/home"
            },
            onComplete => {},
            onAbort => {}
          );

        } else {
          console.log(err);
        }
      });
    }
  },
  watch: {
    storeNovel(val) {
      if(typeof(val) == "string") {
        val = JSON.parse(val)
      }
      //console.log('this.$store.state.novel: ', val);
      this.form.setFieldsValue({
        name: val.name,
        url: val.url,
        pageEncoding: val.pageEncoding,
        filter: val.filter,
        sort: val.sort.toString(),
        threshold: val.threshold
      });
    }
    /*
      fields () {
        this.form.updateFields({
          name: this.$form.createFormField({
            ...this.fields.name,
            value: this.fields.name.value,
          }),
          url: this.$form.createFormField({
            ...this.fields.url,
            value: this.fields.url.value,
          }),
          displayCount: this.$form.createFormField({
            ...this.fields.displayCount,
            value: this.fields.displayCount.value,
          }),
        });
      },*/
  }
};
</script>