import router from './router'
//const loginRoutePath = '/user/login'
//const defaultRoutePath = '/dashboard/workplace'

router.beforeEach((to, from, next) => {
  //to.meta && typeof to.meta.title !== 'undefined'
  //console.log(from)
  next()
})

router.afterEach(() => {
  //NProgress.done() // finish progress bar
})
