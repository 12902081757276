import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    appCode: "APPCODE e042a31b9b3945e2bf71f2d0ea1769a7c",
    novel: {
      id: 0,
      name: "",
      url: "",
      sort: "1",
      pageEncoding: "utf-8",
      filter: "",
      threshold: 150
    },
    customSettings: {
      fontSize: 26,
      immersive: false,
      visible: false,
      theme: {
        description: "护眼灰",
        color: "#CECECF",
        bgColor: "#3A393C",
        version: 1
      }
    },
    editFormVisible: false
  },
  mutations: {
    setNovel(state, data) {
      state.novel = data;
    },
    setEditFormVisible(state, val) {
      state.editFormVisible = val;
    },
    setCustomSettings(state, data) {
      state.customSettings = data;
    }
  }
});

export default store;