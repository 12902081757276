import Vue from "vue";
import App from "./App";
import Antd from "ant-design-vue/es";
import "ant-design-vue/dist/antd.css";
import store from "./store";
import axios from "axios";
import moment from "moment";
import "moment/locale/zh-cn";
import router from "./router";

import "./permission"

Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;

moment.locale("zh-cn");

if (process.env.NODE_ENV === "development") {
  //代理模式用到
  //axios.defaults.baseURL = '/api';
}
axios.defaults.headers.post["Content-Type"] = "application/json";

Vue.use(Antd);
Vue.config.productionTip = false;

new Vue({
  //el: "#app",
  router,
  store,
  components: {},
  render: h => h(App)
}).$mount('#app');
