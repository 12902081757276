<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
};
//禁止移动端使用缩放，双指放大/双击放大
window.onload = function() {
  //禁双指放大（IOS有效）
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })

  //禁双击放大（待测试）
  var lastTouchEnd = 0;
  document.documentElement.addEventListener('touchend', function (event) {
    var now = Date.now();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
}
</script>