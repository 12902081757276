/* Layout */
import { PageLayout } from "@/layouts";

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  name: '404',
  path: '/404',
  //redirect: '/404',
  component: () => import('@/views/404'),
  hidden: true
}

export const constantRoutes = [
  {
    name: 'home',
    path: "",
    component: PageLayout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Novel"),
        //meta: { title: '控制台', icon: 'dashboard', affix: true }
      },
    ]
  },

  {
    path:'',
    //name: 'chapters',
    component: PageLayout,
    redirect: "/chapters",
    children: [
      {
        path: "/chapters",
        name: "chapters",
        component: () => import("@/views/Chapters.vue")
        //meta: { title: '控制台', icon: 'dashboard', affix: true }
      }
    ]
  },

  {
    path: "",
    //name: "content",
    component: PageLayout,
    redirect: "/content",
    children: [
      {
        path: "/content",
        name: "content",
        component: () => import("@/views/Content")
        //meta: { title: '控制台', icon: 'dashboard', affix: true }
      }
    ]
  },

  {
    path: "",
    component: () => import("@/views/Immersive"),
    children:[{
      path: "/immersive",
      name: "immersive",
    }],
    hidden: true
  },

  {
    path: "",
    //name: "logs",
    component: PageLayout,
    redirect: "/logs",
    children: [
      {
        path: "/logs",
        name: "logs",
        component: () => import("@/views/Logs")
        //meta: { title: '控制台', icon: 'dashboard', affix: true }
      }
    ]
  },

  notFoundRouter,

  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true }
];