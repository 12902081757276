<template>
  <a-config-provider :locale="zh_CN">
    
    <a-layout class="layout">
      <a-layout-header id="header">
        <!-- <div class="logo" /> -->
        <a-menu
          mode="horizontal"
          theme="light"
          :style="{ lineHeight: '63px' }"
        >
          <a-menu-item key="home">
            <router-link v-bind:to="'/home'"><a-icon type="home" />首页</router-link>
          </a-menu-item>
          <a-sub-menu key="book">
            <span slot="title" class="submenu-title-wrapper"
            ><a-icon type="book" />小说</span>

            <a-menu-item @click="showDrawer">新增</a-menu-item>
            <a-menu-item @click="removeCache">清理缓存</a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="3">
          <span slot="title" class="submenu-title-wrapper"
            ><a-icon type="api" />其它</span>
            <a-menu-item>
              <router-link v-bind:to="'/logs'">查看日志</router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-header>
      <a-layout-content style="padding: 0 5px" class="layout-content">
        <div class="layout-content">
      <a-breadcrumb :style="{ margin: '25px 0', padding:'15px', backgroundColor:'#fff', borderRadius: '10px'}" class="box-shadow">
        <a-breadcrumb-item href="">
          <a-icon type="home" />
          <span><router-link :to="'home'">首页</router-link></span>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="this.$route.name == 'chapters'">{{this.$route.query.name}}</a-breadcrumb-item>
        <a-breadcrumb-item v-if="this.$route.name == 'content'">
          <router-link
            v-bind:to="{name:'chapters', query:{ id: this.$route.query.id, name: this.$route.query.name }}"
          >{{this.$route.query.name}}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="this.$route.name == 'content'">正文</a-breadcrumb-item>
      </a-breadcrumb>
      <div :style="{ margin:'24px 0', background: '#fff', borderRadius: '10px', padding: '24px', minHeight: '280px' }" class="box-shadow">
        <router-view></router-view>
      </div>
      </div>
    </a-layout-content>
<!--
      <a-layout-content :style="{ padding: '0px 0px' }">
        <div :style="{ background: '#fff', padding: '15px', minHeight: '480px' }">
          
        </div>
      </a-layout-content>
-->
      <!-- 小说录入Drawer表单 Begin
      :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
       -->
      <a-drawer
        title="编辑小说"
        @close="handleOnClose"
        :visible="this.$store.state.editFormVisible"
      >
        <NovelForm :fields="fields" @change="handleFormChange" />
      </a-drawer>
      <!-- 小说录入Drawer表单 End -->

      <a-layout-footer :style="{ textAlign: 'center' }">
        <p>Copyright © 2020 Ekin's Laboratory</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备20001898号-1</a> ｜ <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备20001898号-2</a>
        </p>
        <template v-for="(item,i) in frameworks">
          <a-tooltip :key="i">
            <template slot="title">{{item.version}}</template>
            <a-tag color="#2db7f5">{{item.name}}</a-tag>
          </a-tooltip>
        </template>
      </a-layout-footer>
    </a-layout>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import { version } from "ant-design-vue/es";
import NovelForm from "../components/NovelForm";

const base_API_URL = "api/novel/addNovel";
export default {
  /*
  provide () {
    return {
      reload:this.reload
    }
  },
  
  */
  data() {
    return {
      zh_CN,
      moment,
      version,
      drawerVisible: false,
      //isRouterAlive: true,
      novel: [],
      data: {
        novel: []
      },
      fields: {
        id: {
          value: 0
        },
        name: {
          value: ""
        },
        url: {
          value: ""
        },
        pageEncoding :{
          value: "utf-8"
        },
        filter: {
          value: ""
        },
        sort: {
          value: "1"
        },
        threshold:{
          value: 150
        }
      },
      frameworks: [
        { name: "Ant Design Vue", version: version },
        { name: ".NET", version: "5.0.103" }
      ]
    };
  },
  created() {
    //document.title = "个人开发实验室";
  },
  components: {
    NovelForm
  },
  computed: {
    storeNovel() {
      //获取store中的数据，需要将代码放置在computed中方可。
      return this.$store.state.novel;
    },
    getEditFormVisible() {
      return this.$store.state.editFormVisible;
    }
  },
  watch: {
    drawerVisible(val) {
      this.$store.state.editFormVisible = val;
      //console.log("watch => ",val);
    }
  },
  methods: {
    //接收来自子路由视图的数据
    fillNovelData(val) {
      this.drawerVisible = true;
      this.fields = {
        id: {
          value: val.id
        },
        name: {
          value: val.name
        },
        url: {
          value: val.url
        },
        pageEncoding :{
          value: val.pageEncoding
        },
        filter: {
          value: val.filter
        },
        sort: {
          value: val.sort
        },
        threshold:{
          value: val.threshold
        }
      };
    },
    /*
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(()=>{
        this.isRouterAlive =true;
      });
    },
    */
    resetFields() {
      var data = {
        id: 0,
        name: "",
        url: "",
        pageEncoding:"UTF-8",
        filter:"",
        sort: 1,
        threshold:150
      };
      this.$store.commit("setNovel", data);
    },
    showDrawer() {
      this.resetFields();
      this.drawerVisible = true;
    },
    removeCache() {
      //localStorage.clear();
      sessionStorage.clear();
      this.$message.success("缓存清理完毕！");
    },
    handleOnClose() {
      this.resetFields();
      //console.log(data);

      this.$store.commit("setEditFormVisible", false);
      this.drawerVisible = false;
    },
    handleFormChange(changedFields) {
      //console.log('changedFields', changedFields);
      this.fields = { ...this.fields, ...changedFields };
    }
  }
};
</script>

<style>
.ant-drawer-body {
  padding: 15px;
}

#header {
  background: #fff;
  box-shadow: 0 2px 16px #D9D9D9;
  position: relative;
  z-index: 10;
  max-width: 100%;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
  text-align: center;
}



/*样式3*/
@media (min-width: 1200px) {
  #header {
    background: #fff;
    box-shadow: 0 2px 16px #D9D9D9;
    position: relative;
    z-index: 10;
    /*width: 980px;*/
    width:100%;
    margin:0 auto;
  }

  .layout-content {
    width:980px;
    margin:0 auto;
  }
}

.box-shadow {
  -moz-box-shadow:0px 0px 16px #D9D9D9; 
  -webkit-box-shadow:0px 0px 16px #D9D9D9; 
  box-shadow:0px 0px 16px #D9D9D9;
}
</style>